import React from "react"
import { Heading } from "../components/typogarphy/Heading"
import { Paragraph } from "../components/typogarphy/Paragraph"
const NotFoundPage = () => (
  <>
    <Heading variant="h1">NOT FOUND</Heading>
    <Paragraph>
      You just hit a route that doesn&#39;t exist... the sadness.
    </Paragraph>
  </>
)

export default NotFoundPage
